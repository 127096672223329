<template>
  <div>
    <ElRow type="flex" justify="center">
      <div class="login-form">
        <div v-if="registrationResult" class="text-center">
          <SuccessButton></SuccessButton>
          <ElRow type="flex" justify="center" style="margin: -5px 0 20px 0">
            <ElCol :span="24" class="text-center">
              <span class="text-center">
                <br />
                {{ $t('success_registration') }}
                <span
                  v-if="!form.token"
                  v-html="$t('email_confirm', { email: form.email })"
                  style="line-height: 1.5em; margin: 15px 0 0; display: inline-block"
                ></span>
              </span>
            </ElCol>
          </ElRow>
          <ElRow type="flex" justify="center" style="margin: 20px 0 20px 0">
            <ElCol :span="24">
              <RouterLink to="/login">
                <ElButton type="primary" id="login" class="enter-button" style="width: 100%">{{
                  $t('login')
                }}</ElButton>
              </RouterLink>
            </ElCol>
          </ElRow>
        </div>

        <div v-if="!registrationResult">
          <ElRow type="flex" justify="center">
            <ElCol class="login-title">{{ $t('registration') }}</ElCol>
          </ElRow>
          <Transition name="el-fade-in">
            <ElRow style="margin: 0 0 0 0" v-if="failRegistration">
              <ElCol type="flex" justify="center" style="margin: 0 0 -10px 0">
                <p class="fail-login">{{ registrationErrorMessageText }}</p>
              </ElCol>
            </ElRow>
          </Transition>

          <ElRow style="margin-bottom: 0px">
            <ElForm :model="form" :rules="rules" ref="form" style="margin: 15px 0 0 0">
              <ElFormItem prop="email">
                <ElInput
                  id="email"
                  v-model="form.email"
                  :disabled="emailFromQuery"
                  placeholder="Email"
                  @keyup.native="handleFormInput"
                ></ElInput>
              </ElFormItem>
              <ElFormItem prop="password">
                <ElInput
                  id="password"
                  type="password"
                  v-model="form.password"
                  :placeholder="this.$t('password')"
                  @keyup.native="handleFormInput"
                ></ElInput>
              </ElFormItem>
              <ElFormItem prop="confirm_password">
                <ElInput
                  id="password2"
                  type="password"
                  v-model="form.confirm_password"
                  @keyup.native.enter="submitForm('form')"
                  :placeholder="this.$t('confirm_password')"
                  @keyup.native="handleFormInput"
                ></ElInput>
              </ElFormItem>

              <ElFormItem prop="terms_conditions">
                <div class="agreement">
                  <ElCheckbox
                    class="terms_checkbox"
                    v-model="form.terms_conditions"
                    @change="handleFormInput"
                  />

                  <div class="agreement-text">
                    {{ $t('terms_conditions_1') }}
                    <a target="_blank" :href="TERMS_AND_CONDITIONS_LINK">{{
                      $t('terms_conditions_2')
                    }}</a>
                    {{ $t('terms_conditions_3') }}
                    <a target="_blank" :href="PRIVACY_POLICY">{{ $t('terms_conditions_4') }}</a>
                  </div>
                </div>
              </ElFormItem>

              <ElFormItem style="margin-bottom: 0px">
                <ElRow type="flex" justify="center" style="margin-bottom: 15px">
                  <ElCol :span="24">
                    <ElButton
                      id="submit"
                      type="primary"
                      class="enter-button"
                      :loading="loading"
                      style="width: 100%"
                      @click="submitForm('form')"
                      >{{ $t('create_account') }}</ElButton
                    >
                    <VueRecaptcha
                      v-if="globalRecaptcha.enabled && globalRecaptcha.key"
                      ref="recaptcha"
                      size="invisible"
                      loadRecaptchaScript
                      @verify="registrationUser"
                      @expired="submitForm"
                      :sitekey="globalRecaptcha.key"
                    ></VueRecaptcha>
                  </ElCol>
                </ElRow>
              </ElFormItem>
            </ElForm>
          </ElRow>
        </div>

        <ElRow v-if="!registrationResult" type="flex" justify="center">
          <ElCol class="text-center form-footer-text-block">
            <span class="text-center">
              {{ $t('have_account') }}
              <router-link to="/login">{{ $t('sign_in') }}</router-link>
            </span>
          </ElCol>
        </ElRow>
      </div>
    </ElRow>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import SuccessButton from '../components/Success.vue';
import mirrorKey from 'mirrorkey';
import sessionKeys from '@/sessionStorageKeys';

const registrationFailReasons = mirrorKey({
  FAIL_BACKEND: null,
  USER_ALREADY_EXISTS: null,
  DEFAULT: null,
  TOKET_EXPIRED: null,
  TOKEN_NOT_FIT: null,
});

export default {
  components: { VueRecaptcha, SuccessButton },

  i18n: {
    messages: {
      ru: {
        sign_in: 'Войти',
        have_account: 'Уже есть аккаунт?',
        registration: 'Регистрация',
        create_account: 'Создать аккаунт',
        success_registration: 'Вы были успешно зарегистрированы!',
        email_confirm:
          'Мы отправили электронное письмо со ссылкой для подтверждения {email} для активации вашей учетной записи.',
        terms_conditions_1: 'Я прочитал и согласен с',
        terms_conditions_2: 'политикой конфиденциальности',
        terms_conditions_3: 'а также с',
        terms_conditions_4: 'условиями пользования',
        validation_terms: 'Вы должны принять условия',
        user_already_exists: 'Пользователь уже существует',
        token_expired: 'Приглашение не активно',
        token_not_fit_to_email: 'Token регистрации не подходит для этой электронной почты',
      },
      uk: {
        sign_in: 'Увійти',
        have_account: 'Вже є аккаунт?',
        registration: 'Зареєструватися',
        create_account: 'Створити аккаунт',
        success_registration: 'Ви успішно зареєстровані!',
        email_confirm:
          'Ми надіслали електронний лист із посиланням для підтвердження на {email}, щоб активувати ваш обліковий запис.',
        terms_conditions_1: 'Я ознайомився і згоден з',
        terms_conditions_2: 'політикою конфіденційності',
        terms_conditions_3: 'а також з',
        terms_conditions_4: 'умовами користування',
        validation_terms: 'Ви повинні прийняти умови',
        user_already_exists: 'Користувач вже існує',
        token_expired: 'Запрошення не активне',
        token_not_fit_to_email: 'Token реєстрації не підходить до цієї електронної пошти',
      },
      en: {
        sign_in: 'Sign in',
        have_account: 'Already have an account?',
        registration: 'Registration',
        create_account: 'Create an account',
        success_registration: 'You have been successfully registered!',
        email_confirm:
          'We have sent an email with a confirmation link to {email} to activate your account.',
        terms_conditions_1: 'I have read  and accept',
        terms_conditions_2: "'Terms and Conditions'",
        terms_conditions_3: 'and',
        terms_conditions_4: "'Privacy Policy'",
        validation_terms: 'You must accept the terms',
        user_already_exists: 'User already exists',
        token_expired: 'Invite not active',
        token_not_fit_to_email: 'This email not fit for this token',
      },
    },
  },
  created() {
    this.form.email = this.$session.get(sessionKeys.EMAIL) || '';
    this.form.token = this.$session.get(sessionKeys.TOKEN) || '';
    const regByToken = window.APP_DATA.preferences.registration_by_token;

    if (regByToken && !this.form.token) {
      this.$router.push('/login');
    }
  },

  data() {
    const validatePassEqual = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('validation_password')));
      } else if (value !== this.form.password) {
        callback(new Error(this.$t('passwords_must_match')));
      } else {
        callback();
      }
    };

    const validateTerm = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('validation_terms')));
      } else {
        callback();
      }
    };

    return {
      formState: null,
      loading: false,
      registrationResult: false,
      fail: false,
      failRegistration: null,
      registrationFailReason: null,
      TERMS_AND_CONDITIONS_LINK: window.APP_DATA.preferences.terms_url,
      PRIVACY_POLICY: window.APP_DATA.preferences.privacy_url,
      form: {
        email: '',
        password: '',
        confirm_password: '',
        terms_conditions: false,
        token: '',
      },
      rules: {
        email: [
          {
            required: true,
            message: this.$t('validation_email_require'),
            trigger: 'blur',
          },
          {
            type: 'email',
            required: true,
            message: this.$t('validation_email'),
            trigger: 'blur',
          },
        ],
        terms_conditions: [
          {
            validator: validateTerm,
            trigger: 'blur',
          },
        ],
        password: [
          {
            required: true,
            message: this.$t('validation_password'),
            trigger: 'blur',
          },
          {
            range: true,
            min: 6,
            message: this.$t('validation_password_length', { n: 6 }),
            trigger: 'blur',
          },
        ],
        confirm_password: [{ validator: validatePassEqual, trigger: 'blur' }],
      },
    };
  },

  methods: {
    saveFormState() {
      this.formState = this.getCurrentFormState();
    },

    getCurrentFormState() {
      const { email, password, confirm_password, terms_conditions } = this.form;

      return JSON.stringify({
        email,
        password,
        confirm_password,
        terms_conditions,
      });
    },

    handleFormInput() {
      if (this.failRegistration && this.formState !== this.getCurrentFormState()) {
        this.failRegistration = false;
        this.saveFormState();
      }
    },

    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true;
          this.fail = false;

          if (this.globalRecaptcha.enabled && this.globalRecaptcha.key) {
            this.$refs.recaptcha.execute();
          } else {
            this.registrationUser(null);
          }
        }
      });
    },

    registrationUser(reCaptchaKey) {
      const ErrorToken = {
        TOKEN_NOT_EXIST: 'Token does not exist or expired',
        EMAIL_NOT_FIT: 'This email not fit for this token',
      };

      this.failRegistration = false;

      delete this.form.terms_conditions;

      const data = {
        ...this.form,
        'g-recaptcha-response': reCaptchaKey || null,
      };

      this.$http.post('/registration', data).then(
        response => {
          this.registrationResult = true;
          this.loading = false;
          this.$session.remove(sessionKeys.TOKEN);
          localStorage.setItem('userEmail', this.form.email);
        },
        error => {
          this.failRegistration = true;
          this.form.password = '';
          this.form.confirm_password = '';
          this.loading = false;

          const { response } = error;

          if (response.status === 500) {
            this.registrationFailReason = registrationFailReasons.FAIL_BACKEND;
          } else if (response.status == 400 && response.data && response.data.errors) {
            const { email } = response.data.errors;

            if (email && email.match(/^User.*exists$/)) {
              this.registrationFailReason = registrationFailReasons.USER_ALREADY_EXISTS;
            } else {
              this.registrationFailReason = registrationFailReasons.DEFAULT;
            }
          } else if (response.status === 422 && response.data && response.data.errors) {
            if (response.data.errors.token === ErrorToken.TOKEN_NOT_EXIST) {
              this.registrationFailReason = registrationFailReasons.TOKET_EXPIRED;
            } else if (response.data.errors.token === ErrorToken.EMAIL_NOT_FIT) {
              this.registrationFailReason = registrationFailReasons.TOKEN_NOT_FIT;
            }
          } else {
            this.registrationFailReason = registrationFailReasons.DEFAULT;
          }
        }
      );

      if (this.$refs.recaptcha !== undefined) {
        this.$refs.recaptcha.reset();
      }
    },
  },

  computed: {
    inviteToken() {
      return this.$route.query.inviteToken;
    },

    emailFromQuery() {
      return !!this.$session.get(sessionKeys.EMAIL);
    },

    registrationErrorMessageText() {
      let translationKey;

      switch (this.registrationFailReason) {
        case registrationFailReasons.FAIL_BACKEND:
          translationKey = 'server_failure';
          break;
        case registrationFailReasons.USER_ALREADY_EXISTS:
          translationKey = 'user_already_exists';
          break;
        case registrationFailReasons.TOKET_EXPIRED:
          translationKey = 'token_expired';
          break;
        case registrationFailReasons.TOKEN_NOT_FIT:
          translationKey = 'token_not_fit_to_email';
          break;
        default:
          translationKey = 'server_validation_failure';
          break;
      }

      return this.$t(translationKey);
    },
  },
};
</script>

<style scoped lang="scss">
.terms_checkbox {
  display: flex;
  white-space: normal;
  //   margin: 22px 0;
}
.fail-login {
  color: #ff4949;
  font-size: 14px;
  text-align: center;
}

.login-form {
  margin: 0 auto;
  width: 273px;
  background: white;
  padding: 30px 60px 15px 60px;
  color: #424c61;
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6), 0 2px 4px 0 rgba(232, 237, 250, 0.5);

  @media screen and (max-width: 500px) {
    padding: 15px 30px 15px 30px;
  }
}

.login-title {
  text-align: center;
  font-size: 22px;
  margin-bottom: -5px;
}

.ElRow {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

.ElCol {
  border-radius: 4px;
}

.text-center {
  text-align: center;
}

.agreement {
  display: flex;
  flex-direction: row;
}

.agreement-text {
  line-height: 1.3em;
  flex-grow: 1;
  margin-left: 10px;
}
</style>
