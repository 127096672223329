<template>
  <section class="change-password-form">
    <div class="change-password-form__container" v-loading="pageLoading">
      <header class="change-password-form__header">
        <h1 class="change-password-form__title">
          {{ $t('recovery_password') }}
        </h1>
      </header>

      <div class="change-password-form__content">
        <ElForm
          v-if="changePasswordSuccess === null && !backendFail"
          ref="form"
          :model="form"
          :rules="rules"
        >
          <ElFormItem prop="password">
            <ElInput v-model="form.password" type="password" :placeholder="$t('password')" />
          </ElFormItem>

          <ElFormItem prop="confirm_password">
            <ElInput
              v-model="form.confirm_password"
              type="password"
              :placeholder="$t('confirm_password')"
            />
          </ElFormItem>

          <ElButton class="change-password-form__btn--fluid" type="primary" @click="submitForm">
            {{ $t('change_password_button') }}
          </ElButton>
        </ElForm>

        <div class="change-password-form__change-succeeded" v-if="changePasswordSuccess">
          <SuccessButton />

          <p class="change-password-form__message">
            {{ $t('change_password_success') }}
          </p>

          <ElButton class="change-password-form__btn--fluid" type="primary" @click="goToLogin">
            {{ $t('login') }}
          </ElButton>
        </div>

        <div class="change-password-form__link-not-active" v-if="backendFail">
          <p class="change-password-form__message">
            {{ $t('link_is_not_active') }}
          </p>

          <RouterLink to="/reset">
            <ElButton type="primary" class="change-password-form__btn--fluid">
              {{ $t('change_password') }}
            </ElButton>
          </RouterLink>
        </div>
      </div>

      <footer class="change-password-form__footer">
        {{ $t('return_to_login') }}
        <RouterLink to="/login">{{ $t('sign_in') }}</RouterLink>
      </footer>
    </div>
  </section>
</template>

<script>
import SuccessButton from '../components/Success.vue';

export default {
  components: {
    SuccessButton,
  },

  async created() {
    const { token } = this.$route.params;
    this.form.token = token;
    await this.getUserByToken(token);
  },

  data() {
    const validatePassEqual = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('validation_password')));
      } else if (value !== this.form.password) {
        callback(new Error(this.$t('passwords_must_match')));
      } else {
        callback();
      }
    };

    return {
      pageLoading: false,
      backendFail: null,
      changePasswordSuccess: null,
      userLoaded: false,
      form: {
        token: null,
        password: '',
        confirm_password: '',
      },
      rules: {
        password: [
          {
            required: true,
            message: this.$t('validation_password'),
            trigger: 'blur',
          },
          {
            range: true,
            min: 6,
            message: this.$t('validation_password_length', { n: 6 }),
            trigger: 'blur',
          },
        ],
        confirm_password: [{ validator: validatePassEqual, trigger: 'blur' }],
      },
    };
  },

  methods: {
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.changePassword();
        }
      });
    },

    async changePassword() {
      try {
        this.pageLoading = true;

        await this.$http.post(`/resetting/password`, this.form);
        this.changePasswordSuccess = true;
      } catch (e) {
        this.processChangePasswordError(e);
      } finally {
        this.pageLoading = false;
      }
    },

    processChangePasswordError(e) {
      this.backendFail = true;
    },

    async getUserByToken(token) {
      try {
        this.pageLoading = true;
        return await this.$http.get(`/resetting/${token}`);
      } catch (e) {
        this.processUserGettingError(e);
      } finally {
        this.pageLoading = false;
      }
    },

    processUserGettingError(e) {
      this.backendFail = true;
    },

    goToLogin() {
      this.$router.push({ name: 'login' });
    },
  },
  i18n: {
    messages: {
      ru: {
        sign_in: 'Входa',
        change_password: 'Смена пароля',
        link_is_not_active: 'Данная ссылка не активна, отправьте запрос на смену пароля повторно',
        recovery_password: 'Восстановление пароля',
        return_to_login: 'Вернуться на страницу',
        change_password_button: 'Сменить пароль',
        change_password_success: 'Пароль успешно изменён',
      },
      uk: {
        sign_in: 'Входу',
        change_password: 'Зміна пароля',
        recovery_password: 'Відновлення пароля',
        return_to_login: 'Повернутися на сторінку',
        link_is_not_active: 'Дане посилання не активне, надішліть запит на зміну пароля повторно',
        change_password_button: 'Змінити пароль',
        change_password_success: 'Пароль успішно змінено',
      },
      en: {
        sign_in: 'Login',
        change_password: 'Change password',
        recovery_password: 'Password recovery',
        return_to_login: 'Return to the page',
        link_is_not_active:
          'This link is not active, submit a request to change the password again',
        change_password_button: 'Change the password',
        change_password_success: 'Password successfully changed',
      },
    },
  },
};
</script>

<style lang="scss">
.change-password-form {
  &__container {
    margin: 0 auto;
    width: 273px;
    background: white;
    padding: 30px 60px 15px 60px;
    color: #424c61;
    box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6), 0 2px 4px 0 rgba(232, 237, 250, 0.5);
    text-align: center;

    @media screen and (max-width: 500px) {
      padding: 15px 30px 15px 30px;
    }
  }

  &__header {
    margin-bottom: 20px;
  }

  &__title {
    font-size: 22px;
    font-weight: 400;
    margin: 0;
  }

  &__btn {
    &--fluid {
      width: 100%;
    }
  }

  &__message {
    margin: 30px 0;
  }

  &__footer {
    font-size: 14px;
    padding: 30px 0 15px 0;
  }
}
</style>
